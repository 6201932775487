import React from "react"
import Layout from "../component/Layout"
import Logo from "../images/logo/dorlands_tree_logo.png"
// import Contact from "../component/Contact"
import SEO from "../component/SEO"
// import ContactCH from "../component/ContactCH"
import { Link } from "gatsby"
import "../styles/contact.scss"

const contactUs = () => {
  return (
    <Layout>
      <SEO title="Contact Us" />
      <section className="contact-us">
        <div className="contact-info">
          <div className="dorlands_logo_contact_us">
            <a href="index.html">
              <img src={Logo} alt="Dorlands Holidays Logo" />
            </a>
          </div>
          <div className="address_info">
            <h4>Dorlands</h4>
            <p>Kilgwrrwg, Devauden</p>
            <p>Chepstow</p>
            <p>NP16 6PT</p>
          </div>

          <a className="tel" href="tel:07837871572">
            07837 871572
          </a>

          <a className="email" href="mailto:info@dorlands.co.uk">
            info@dorlands.co.uk
          </a>

          {/* <h4>CONTACT US</h4>
          <p>We aim to respond within 24hrs.</p> */}
        </div>
      </section>

      <div className="enquires">
        <li className="enqButtons">
          <Link
            to="/dorlands-house#contact"
            activeStyle={{
              background: "#5b58c5",
              borderRadius: "5px",
            }}
          >
            Contact Dorlands House
          </Link>
        </li>
        <li className="enqButtons">
          <Link
            to="/dorlands-country-house#contact"
            activeStyle={{
              background: "#5b58c5",
              borderRadius: "5px",
            }}
          >
            Contact Dorlands Country House
            <br />
            &amp; Franky's Hideout
          </Link>
        </li>
      </div>
    </Layout>
  )
}

export default contactUs
